import React from 'react';
import { TextField } from '@material-ui/core';
import { SelectOption } from '../interfaces';
import Autocomplete from '@material-ui/lab/Autocomplete';

export interface InputAutocompleteProps {
    id: string;
    label: string;
    options: SelectOption[];
    onChange?: (value?: string) => void
}

class InputAutocomplete extends React.Component<InputAutocompleteProps> {

    render () {
        return (
            <Autocomplete
                id={this.props.id}
                options={this.props.options.sort((a, b) => -b.name.charAt(0).localeCompare(a.name.charAt(0)))}
                groupBy={(option) => option.name.charAt(0)}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} size="small" placeholder={this.props.label} variant="outlined" />}
                onChange={(_, newInputValue) => {
                    this.props.onChange?.(newInputValue?.value);
                }}
                size="small"
            />
        );
    };

}

export { InputAutocomplete };
