import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../reducers';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';

export interface UpdatePasswordAction {
    type: typeof UPDATE_PASSWORD,
    payload: string
}

export type Actions = UpdatePasswordAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
