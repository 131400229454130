import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../reducers';
import { Novel } from '../../interfaces';

export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_NOVELS = 'UPDATE_NOVELS';
export const APPEND_NOVELS = 'APPEND_NOVELS';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateNovelsAction {
    type: typeof UPDATE_NOVELS,
    payload: Novel[]
}

export interface AppendNovelsAction {
    type: typeof APPEND_NOVELS,
    payload: Novel[]
}

export type Actions =
UpdateLoadingAction |
UpdateNovelsAction |
AppendNovelsAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
