import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavElement } from '.';
import { Literals } from '../interfaces';
import { remove_html_tags } from '../util/html';

export interface HeaderProps {
    literals: Literals
}

class Header extends React.Component<HeaderProps> {

    render () {
        const { literals } = this.props;
        return (
            <>
                <img src="/logo.png" alt="logo" style={{ width: '250px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                <nav className="nav">
                    <div className="nav-items col_r2_d5">
                        <NavLink to="/" exact activeClassName="active" className="nav-item">
                            <NavElement text={remove_html_tags(literals['home.link_text'])} />
                        </NavLink>
                        <NavLink to="/relatos" activeClassName="active" className="nav-item">
                            <NavElement text={remove_html_tags(literals['stories.link_text'])} />
                        </NavLink>
                        <NavLink to="/novelas" activeClassName="active" className="nav-item">
                            <NavElement text={remove_html_tags(literals['novels.link_text'])} />
                        </NavLink>
                        <NavLink to="/escribes" activeClassName="active" className="nav-item">
                            <NavElement text={remove_html_tags(literals['write.link_text'])} />
                        </NavLink>
                        <NavLink to="/contacto" activeClassName="active" className="nav-item">
                            <NavElement text={remove_html_tags(literals['contact.link_text'])} />
                        </NavLink>
                    </div>
                </nav>
            </>
        );
    };

}

export { Header };
