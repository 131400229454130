import React from 'react';

export interface StoryCardLoadingProps {
}

class StoryCardLoading extends React.Component<StoryCardLoadingProps> {

    render () {
        return (
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
            </div>
        );
    };

}

export { StoryCardLoading };
