import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../reducers';
import { Story } from '../../interfaces';

export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_STORY = 'UPDATE_STORY';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateStoryAction {
    type: typeof UPDATE_STORY,
    payload: Story
}

export type Actions =
UpdateLoadingAction |
UpdateStoryAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
