import { combineReducers } from 'redux';
import AuthReducer from './auth/Reducer';
import LiteralsReducer from './literals/Reducer';
import HomeReducer from '../screens/home/Reducer';
import NovelsReducer from '../screens/novels/Reducer';
import NovelDetailReducer from '../screens/novelDetail/Reducer';
import StoriesReducer from '../screens/stories/Reducer';
import StoryDetailReducer from '../screens/storyDetail/Reducer';
import ContactReducer from '../screens/contact/Reducer';
import WriteReducer from '../screens/write/Reducer';
import LoginReducer from '../screens/login/Reducer';

const rootReducer = combineReducers({
    auth: AuthReducer,
    literals: LiteralsReducer,
    home: HomeReducer,
    novels: NovelsReducer,
    novelDetail: NovelDetailReducer,
    stories: StoriesReducer,
    storyDetail: StoryDetailReducer,
    contact: ContactReducer,
    write: WriteReducer,
    login: LoginReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;