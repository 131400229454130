import { get_novels } from '../../api';
import {
    UPDATE_LOADING,
    UPDATE_NOVELS,
    APPEND_NOVELS,
    Actions,
    AppThunk,
} from './ActionTypes';
import { novel_map } from "../../interfaces/Novel.map";

export const getNovels = (skip?: number): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as Actions);

        let novels = await get_novels(skip);
        novels = novels.data.map(novel_map);

        dispatch({
            type: skip ? APPEND_NOVELS : UPDATE_NOVELS,
            payload: novels
        } as Actions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as Actions);
    }
}