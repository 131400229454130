import React from 'react';
import { RootState } from '../../reducers';
import { connect, ConnectedProps } from 'react-redux';
import { Header } from '../../components';
import { updateLiterals } from '../../reducers/literals/Actions';
import { remove_html_tags } from '../../util/html';
import { RouteComponentProps } from 'react-router-dom';

const mapStateToProps = (state: RootState) => {
	return {
		write_state: state.write,
		literals_state: state.literals,
	}
}

const connector = connect(mapStateToProps, {
	updateLiterals,
});

interface ParamTypes {
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector>;

interface State {
}

class Index extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {
		this.props.updateLiterals();
	}

	render() {
		const { literals } = this.props.literals_state;
		return (
			<>
				<Header literals={literals} />
				<div className="content write-screen">
					{literals['write.title'] && <h2 className="title-main">{remove_html_tags(literals['write.title'])}</h2>}
					<div className="col_r1_d2">
						<div>
							<img src="./img/computer.jpeg" className="img" alt="computer" />
						</div>
						<div>
							{literals['write.paragraph'] && <div className="paragraph-container" dangerouslySetInnerHTML={{__html: literals['write.paragraph']}} />}
						</div>
					</div>
				</div>
			</>
		);
	}

}

export default connector(Index);
