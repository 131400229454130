import axios, { AxiosRequestConfig } from 'axios';
import moment from 'moment';

export const with_auth = (config: AxiosRequestConfig = {}) => {
    return { ...config, headers: { ...config.headers, Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` }};
}

export const get_literals = () => {
     return axios.get(`${process.env.REACT_APP_API_URL}/apps/${process.env.REACT_APP_API_APP_ID}/schemas/${process.env.REACT_APP_API_LITERALS_SCHEMA_ID}/data`, with_auth())
     .then(response => {
         if (response.status === 200) {
             return response.data;
         }
         return [];
     })
     .catch(error => {
         console.error(error);
         return [];
     });
}

export const get_stories = (skip?: number) => {
    if (!skip) {
        skip = 0;
    }
    return axios.get(`${process.env.REACT_APP_API_URL}/apps/${process.env.REACT_APP_API_APP_ID}/schemas/${process.env.REACT_APP_API_STORIES_SCHEMA_ID}/data?limit=4&skip=${skip}&sort=fecha_publicacion(desc)&filter=fecha_publicacion(lte)${moment().format()}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(error => {
        console.error(error);
        return [];
    });
}

export const get_story = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/apps/${process.env.REACT_APP_API_APP_ID}/schemas/${process.env.REACT_APP_API_STORIES_SCHEMA_ID}/data/${id}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(error => {
        console.error(error);
        return [];
    });
}

export const get_novels = (skip?: number) => {
    if (!skip) {
        skip = 0;
    }
    return axios.get(`${process.env.REACT_APP_API_URL}/apps/${process.env.REACT_APP_API_APP_ID}/schemas/${process.env.REACT_APP_API_NOVELS_SCHEMA_ID}/data?limit=4&skip=${skip}&sort=fecha_publicacion(desc)&filter=fecha_publicacion(lte)${moment().format()}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(error => {
        console.error(error);
        return [];
    });
}

export const get_novel = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/apps/${process.env.REACT_APP_API_APP_ID}/schemas/${process.env.REACT_APP_API_NOVELS_SCHEMA_ID}/data/${id}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(error => {
        console.error(error);
        return [];
    });
}