import React from 'react';

export interface SpinnerProps {
}

class Spinner extends React.Component<SpinnerProps> {

    render () {
        return (
            <div className="d-flex justify-content-center m-3">
                <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        );
    };

}

export { Spinner };
