import { Novel } from '.';

export const novel_map = (novel: any): Novel => ({
    id: novel.id,
    title: novel.fields.titulo,
    image: novel.fields.image ? novel.fields.image[0].fileUrl : '',
    description: novel.fields.description,
    thematic: novel.fields.thematic ? novel.fields.thematic.fields.nombre : '',
    link_pdf: novel.fields.link_archivo ? novel.fields.link_archivo.href : '',
    link_ebook: novel.fields.link_ebook ? novel.fields.link_ebook.href : '',
});