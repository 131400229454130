import React from 'react';

export interface NovelCardLoadingProps {
}

class NovelCardLoading extends React.Component<NovelCardLoadingProps> {

    render () {
        return (
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
            </div>
        );
    };

}

export { NovelCardLoading };
