import { get_literals } from '../../api';
import { Literals } from '../../interfaces';
import {
    UPDATE_LITERALS,
    Actions,
    AppThunk,
} from './ActionTypes';

export const updateLiterals = (): AppThunk => {
    return async dispatch => {

        let literals = await get_literals();
        literals = literals.data.reduce((object: Literals, current: any) => { object[current.fields.codigo] = current.fields.valor; return { ...object }; }, {} as Literals);

        dispatch({
            type: UPDATE_LITERALS,
            payload: literals
        } as Actions);
    }
}