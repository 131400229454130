import React from 'react';
import { RootState } from '../../reducers';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Header, Spinner, StoryCard } from '../../components';
import { getStories } from './Actions';
import { updateLiterals } from '../../reducers/literals/Actions';
import { remove_html_tags } from '../../util/html';
import { RouteComponentProps } from 'react-router-dom';

const mapStateToProps = (state: RootState) => {
	return {
		stories_state: state.stories,
		literals_state: state.literals,
	}
}

const connector = connect(mapStateToProps, {
	getStories,
	updateLiterals,
});

interface ParamTypes {
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector>;

interface State {
}

class Index extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {
		this.props.getStories();
		this.props.updateLiterals();
	}

	renderStories() {
		const { stories } = this.props.stories_state;
		return (
			<>
				{stories.map((story, index) => <StoryCard key={index} story={story} size="big" />)}
			</>
		);
	}

	renderLoadMore() {
		const { loading, stories } = this.props.stories_state;
		const { literals } = this.props.literals_state;
		if (loading) {
			return <Spinner />;
		} else {
			return (
				<div className="horizontal-center">
					<Button className="btn-main" onClick={() => this.props.getStories(stories.length)}>{remove_html_tags(literals['stories.view_more'])}</Button>
				</div>
			);
		}
	}

	render() {
		const { literals } = this.props.literals_state;
		return (
			<>
				<Header literals={literals} />
				<div className="content">
					{literals['stories.title'] && <h2 className="title-main">{remove_html_tags(literals['stories.title'])}</h2>}
					<div className="col_r1_d2">
						{this.renderStories()}
					</div>
					{this.renderLoadMore()}
				</div>
			</>
		);
	}

}

export default connector(Index);
