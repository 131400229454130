import {
    UPDATE_LOADING,
    UPDATE_NOVELS,
    APPEND_NOVELS,
    Actions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Novel } from "../../interfaces";

export interface State {
    loading: boolean,
    novels: Novel[],
}

const INITIAL_STATE: State = {
    loading: false,
    novels: [],
};

const reducer: Reducer<State, Actions> = (state = INITIAL_STATE, action: Actions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_NOVELS:
            return { ...state, novels: action.payload };
        case APPEND_NOVELS:
            return { ...state, novels: [...state.novels, ...action.payload] };
        default:
            return state;
    }
}

export default reducer;