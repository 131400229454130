import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '..';
import { Literals } from '../../interfaces';

export const UPDATE_LITERALS = 'UPDATE_LITERALS';

export interface UpdateTextsAction {
    type: typeof UPDATE_LITERALS,
    payload: Literals
}

export type Actions = UpdateTextsAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
