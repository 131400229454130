import React from 'react';
import { RootState } from '../../reducers';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Header, MaterialIcon, Spinner } from '../../components';
import { getNovel } from './Actions';
import { updateLiterals } from '../../reducers/literals/Actions';
import { RouteComponentProps } from 'react-router-dom';

const mapStateToProps = (state: RootState) => {
	return {
		novel_detail_state: state.novelDetail,
		literals_state: state.literals,
	}
}

const connector = connect(mapStateToProps, {
	getNovel,
	updateLiterals,
});

interface ParamTypes {
	id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector>;

interface State {
}

class Index extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {
		this.props.getNovel(this.props.match.params.id);
		this.props.updateLiterals();
	}

	render() {
		const { novel, loading } = this.props.novel_detail_state;
		const { literals } = this.props.literals_state;
		return (
			<>
				<Header literals={literals} />
				{loading && <Spinner />}
				{!loading &&
					<div className="content novel-screen col_r1_d2">
						{novel?.image && <img className="img" src={novel?.image} alt={novel?.title} />}
						<div className="detail">
							{novel?.title && <h3>{novel?.title}</h3>}
							{novel?.description && <div dangerouslySetInnerHTML={{__html: novel?.description}} />}
							{novel?.thematic && <p><b>Temática:</b> {novel?.thematic}</p>}
							{novel?.link_pdf && <Button className="btn-download" onClick={()=> window.open(novel?.link_pdf, "_blank")}><MaterialIcon icon="get_app" />Descargar PDF</Button>}
							{novel?.link_ebook && <Button className="btn-download" onClick={()=> window.open(novel?.link_ebook, "_blank")}><MaterialIcon icon="get_app" />Descargar EBOOK</Button>}
						</div>
					</div>
				}
			</>
		);
	}

}

export default connector(Index);
