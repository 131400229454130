import React from 'react';
import { RootState } from '../../reducers';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Header, NovelCard, StoryCard } from '../../components';
import { getNovelsAndStories } from './Actions';
import { updateLiterals } from '../../reducers/literals/Actions';
import { remove_html_tags } from '../../util/html';
import { RouteComponentProps } from 'react-router-dom';

const mapStateToProps = (state: RootState) => {
	return {
		home_state: state.home,
		literals_state: state.literals,
	}
}

const connector = connect(mapStateToProps, {
	getNovelsAndStories,
	updateLiterals,
});

interface ParamTypes {
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector>;

interface State {
}

class Index extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {
		this.props.getNovelsAndStories();
		this.props.updateLiterals();
	}

	renderStories() {
		const { stories } = this.props.home_state;
		return (
			<div className="stories-row-list">
				<h2 className="section-title">Relatos</h2>
				<div className="col_r1_d2">
					{stories.map((story, index) => <StoryCard key={index} story={story} size="small" />)}
				</div>
				{this.renderStoriesLoadMore()}
			</div>
		);
	}

	renderStoriesLoadMore() {
		const { literals } = this.props.literals_state;
		return (
			<div className="horizontal-center mb-5">
				<Button className="btn-main" onClick={() => this.props.history.push('/relatos')}>{remove_html_tags(literals['stories.view_more'])}</Button>
			</div>
		);
	}

	renderNovels() {
		const { novels } = this.props.home_state;
		return (
			<div className="novels-row-list">
				<h2 className="section-title">Novelas</h2>
				<div className="col_r1_d2">
					{novels.map((novel, index) => <NovelCard key={index} novel={novel} size="small" />)}
				</div>
				{this.renderNovelsLoadMore()}
			</div>
		);
	}

	renderNovelsLoadMore() {
		const { literals } = this.props.literals_state;
		return (
			<div className="horizontal-center mb-5">
				<Button className="btn-main" onClick={() => this.props.history.push('/novelas')}>{remove_html_tags(literals['novels.view_more'])}</Button>
			</div>
		);
	}

	render() {
		const { literals } = this.props.literals_state;
		return (
			<>
				<Header literals={literals} />
				<div className="content">
					{literals['home.title'] && <h2 className="title-main">{remove_html_tags((literals['home.title']))}</h2>}
					{literals['home.paragraph'] && <div className="text-center"><div dangerouslySetInnerHTML={{__html: literals['home.paragraph']}} /></div>}
					{literals['home.paragraph_small'] && <div className="text-center text-italic"><div dangerouslySetInnerHTML={{__html: literals['home.paragraph_small']}} /></div>}
				</div>
				<div className="col_r1_d2">
					{this.renderStories()}
					{this.renderNovels()}
				</div>
			</>
		);
	}

}

export default connector(Index);
