import { get_story } from '../../api';
import {
    UPDATE_LOADING,
    UPDATE_STORY,
    Actions,
    AppThunk,
} from './ActionTypes';
import { story_map } from "../../interfaces/Story.map";

export const getStory = (id: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as Actions);

        let novel = await get_story(id);
        novel = story_map(novel.data);

        dispatch({
            type: UPDATE_STORY,
            payload: novel
        } as Actions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as Actions);
    }
}