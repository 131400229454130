import {
    UPDATE_LITERALS,
    Actions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Literals } from "../../interfaces";

export interface State {
    literals: Literals,
}

const INITIAL_STATE: State = {
    literals: {},
};

const reducer: Reducer<State, Actions> = (state = INITIAL_STATE, action: Actions) => {
    switch(action.type) {
        case UPDATE_LITERALS:
            return { ...state, literals: action.payload };
        default:
            return state;
    }
}

export default reducer;