import { get_novel } from '../../api';
import {
    UPDATE_LOADING,
    UPDATE_NOVEL,
    Actions,
    AppThunk,
} from './ActionTypes';
import { novel_map } from "../../interfaces/Novel.map";

export const getNovel = (id: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as Actions);

        let novel = await get_novel(id);
        novel = novel_map(novel.data);

        dispatch({
            type: UPDATE_NOVEL,
            payload: novel
        } as Actions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as Actions);
    }
}