import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';

export interface InputCheckBoxProps {
    id: string,
    label?: string,
    checked?: boolean,
    disabled?: boolean,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

class InputCheckBox extends React.Component<InputCheckBoxProps> {

    render () {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        id={this.props.id}
                        checked={this.props.checked}
                        disabled={this.props.disabled}
                        onChange={this.props.onChange}
                    />
                }
                label={this.props.label}
                labelPlacement="start"
            />
        );
    };

}

export { InputCheckBox };
