import {
    UPDATE_LOADING,
    UPDATE_NOVELS,
    UPDATE_STORIES,
    Actions,
    AppThunk,
} from './ActionTypes';
import { get_novels, get_stories } from '../../api';
import { novel_map } from '../../interfaces/Novel.map';
import { story_map } from '../../interfaces/Story.map';

export const getNovelsAndStories = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as Actions);

        let novels = await get_novels();
        novels = novels.data.map(novel_map);

        dispatch({
            type: UPDATE_NOVELS,
            payload: novels
        } as Actions);

        let stories = await get_stories();
        stories = stories.data.map(story_map);

        dispatch({
            type: UPDATE_STORIES,
            payload: stories
        } as Actions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as Actions);
    }
}