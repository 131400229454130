import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../reducers';
import { Novel, Story } from '../../interfaces';

export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_NOVELS = 'UPDATE_NOVELS';
export const UPDATE_STORIES = 'UPDATE_STORIES';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateNovelsAction {
    type: typeof UPDATE_NOVELS,
    payload: Novel[]
}

export interface UpdateStoriesAction {
    type: typeof UPDATE_STORIES,
    payload: Story[]
}

export type Actions =
UpdateLoadingAction |
UpdateNovelsAction |
UpdateStoriesAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
