import './App.css';
import './css/main.min.css';
import React from 'react';
import { RootState } from './reducers';
import { connect, ConnectedProps } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { updatePassword } from './reducers/auth/Actions';
import cookies from './util/cookies';
import { are_equal_strings } from './util/form';

import Home from './screens/home/Index';
import Novels from './screens/novels/Index';
import NovelDetail from './screens/novelDetail/Index';
import Stories from './screens/stories/Index';
import StoryDetail from './screens/storyDetail/Index';
import Write from './screens/write/Index';
import Contact from './screens/contact/Index';
import Login from './screens/login/Index';

const mapStateToProps = (state: RootState) => {
    return {
        auth_state: state.auth
    }
}

const connector = connect(mapStateToProps, { updatePassword })

class App extends React.Component<ConnectedProps<typeof connector>> {

    componentDidMount() {
        const password = cookies.get('password');
        this.props.updatePassword(password);
        cookies.addChangeListener((options) => {
            if (options.name === 'password') {
                this.props.updatePassword(options.value);
            }
        });
    }

    render() {
        const { password } = this.props.auth_state;
        return (
            <Router>
                {process.env.REACT_APP_GLOBAL_PWS && password !== '' && process.env.REACT_APP_GLOBAL_PWS !== '' && !are_equal_strings(password || '', process.env.REACT_APP_GLOBAL_PWS || '') &&
                    <Login />}
                {password !== '' && process.env.REACT_APP_GLOBAL_PWS !== '' && are_equal_strings(password || '', process.env.REACT_APP_GLOBAL_PWS || '') &&
                    <Switch>
                        <Route path="/novelas/:id" component={NovelDetail}/>
                        <Route path="/novelas" component={Novels}/>
                        <Route path="/relatos/:id" component={StoryDetail}/>
                        <Route path="/relatos" component={Stories}/>
                        <Route path="/escribes" component={Write}/>
                        <Route path="/contacto" component={Contact}/>
                        <Route path="/" component={Home}/>
                    </Switch>}
            </Router>
        );
    }

}

export default connector(App);
