import React from 'react';

export interface NavElementProps {
    text: string,
}

class NavElement extends React.Component<NavElementProps> {

    render () {
        const { text } = this.props;
        return (
            <p className="m-2">{text}</p>
        );
    };

}

export { NavElement };
