import React from 'react';
import { Link } from 'react-router-dom';
import { Story } from '../interfaces';

export interface StoryCardProps {
    story: Story,
    size: string,
}

class StoryCard extends React.Component<StoryCardProps> {

    render () {
        const { story, size } = this.props;
        return (
            <Link to={`/relatos/${story.id}`} className={`story-card-${size} col_r1_d2`}>
                {size === 'small' &&
                    story.image && <img src={story.image} alt={story.title} />
                }
                {size === 'big' &&
                    <div className={`story-card-${size}-left`}>
                        {story.image && <img src={story.image} alt={story.title} />}
                    </div>
                }
                {size === 'big' &&
                    <div className={`story-card-${size}-right`}>
                        {story.title && <h2>{story.title}</h2>}
                        {story.description && <div dangerouslySetInnerHTML={{__html: story.description}} />}
                        {story.thematic && <p><b>Temática:</b> {story.thematic}</p>}
                    </div>
                }
            </Link>
        );
    };

}

export { StoryCard };
