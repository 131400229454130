import {
    UPDATE_PASSWORD,
    Actions,
    AppThunk,
} from './ActionTypes';

export const updatePassword = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_PASSWORD,
            payload: value
        } as Actions);
    }
}