import {
    UPDATE_LOADING,
    UPDATE_STORIES,
    APPEND_STORIES,
    Actions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Story } from "../../interfaces";

export interface State {
    loading: boolean,
    stories: Story[],
}

const INITIAL_STATE: State = {
    loading: false,
    stories: [],
};

const reducer: Reducer<State, Actions> = (state = INITIAL_STATE, action: Actions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_STORIES:
            return { ...state, stories: action.payload };
        case APPEND_STORIES:
            return { ...state, stories: [...state.stories, ...action.payload] };
        default:
            return state;
    }
}

export default reducer;