import {
    UPDATE_LOADING,
    UPDATE_NOVEL,
    Actions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Novel } from "../../interfaces";

export interface State {
    loading: boolean,
    novel: Novel|null,
}

const INITIAL_STATE: State = {
    loading: false,
    novel: null,
};

const reducer: Reducer<State, Actions> = (state = INITIAL_STATE, action: Actions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_NOVEL:
            return { ...state, novel: action.payload };
        default:
            return state;
    }
}

export default reducer;