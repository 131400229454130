import React from 'react';
import TextField from '@material-ui/core/TextField';

export interface InputNumberProps {
    id: string,
    label: string,
    value?: number,
    disabled?: boolean,
    helpText?: string,
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

class InputNumber extends React.Component<InputNumberProps> {

    render () {
        return (
            <TextField
                type="number"
                id={this.props.id}
                label={this.props.label}
                value={this.props.value}
                onChange={this.props.onChange}
                disabled={this.props.disabled}
                fullWidth
                error={!!this.props.helpText}
                helperText={this.props.helpText}
            />
        );
    };

}

export { InputNumber };
