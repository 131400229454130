import React from 'react';

export interface MaterialIconProps {
    icon: string,
}

class MaterialIcon extends React.Component<MaterialIconProps> {

    render () {
        return (
            <span className="material-icons">{ this.props.icon }</span>
        );
    };

}

export { MaterialIcon };
