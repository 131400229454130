import {
    UPDATE_LOADING,
    UPDATE_STORIES,
    APPEND_STORIES,
    Actions,
    AppThunk,
} from './ActionTypes';
import { get_stories } from '../../api';
import { story_map } from "../../interfaces/Story.map";

export const getStories = (skip?: number): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as Actions);

        let stories = await get_stories(skip);
        stories = stories.data.map(story_map);

        dispatch({
            type: skip ? APPEND_STORIES : UPDATE_STORIES,
            payload: stories
        } as Actions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as Actions);
    }
}