import React from 'react';
import TextField from '@material-ui/core/TextField';

export interface InputPasswordProps {
    id: string,
    label?: string,
    value?: string,
    placeholder?: string,
    disabled?: boolean,
    helpText?: string,
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
    onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
    onKeyUp?: (e: React.KeyboardEvent<HTMLDivElement>) => void,
}

class InputPassword extends React.Component<InputPasswordProps> {

    render () {
        return (
            <TextField
                type="password"
                id={this.props.id}
                label={this.props.label}
                value={this.props.value}
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                onKeyUp={this.props.onKeyUp}
                variant="outlined"
                size="small"
                fullWidth
                disabled={this.props.disabled}
                error={!!this.props.helpText}
                helperText={this.props.helpText}
            />
        );
    };

}

export { InputPassword };
