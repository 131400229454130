import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../reducers';
import { Novel } from '../../interfaces';

export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_NOVEL = 'UPDATE_NOVEL';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateNovelAction {
    type: typeof UPDATE_NOVEL,
    payload: Novel
}

export type Actions =
UpdateLoadingAction |
UpdateNovelAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
