import React from 'react';
import { RootState } from '../../reducers';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Header, MaterialIcon, Spinner } from '../../components';
import { getStory } from './Actions';
import { updateLiterals } from '../../reducers/literals/Actions';
import { RouteComponentProps } from 'react-router-dom';

const mapStateToProps = (state: RootState) => {
	return {
		story_detail_state: state.storyDetail,
		literals_state: state.literals,
	}
}

const connector = connect(mapStateToProps, {
	getStory,
	updateLiterals,
});

interface ParamTypes {
	id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector>;

interface State {
}

class Index extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {
		this.props.getStory(this.props.match.params.id);
		this.props.updateLiterals();
	}

	render() {
		const { story, loading } = this.props.story_detail_state;
		const { literals } = this.props.literals_state;
		return (
			<>
				<Header literals={literals} />
				{loading && <Spinner />}
				{!loading &&
					<div className="content story-screen col_r1_d2">
						{story?.image && <img className="img" src={story?.image} alt={story?.title} />}
						<div className="detail">
							{story?.title && <h3>{story?.title}</h3>}
							{story?.description && <div dangerouslySetInnerHTML={{__html: story?.description}} />}
							{story?.thematic && <p><b>Temática:</b> {story?.thematic}</p>}
							{story?.link_pdf &&
								<Button className="btn-download" onClick={()=> window.open(story?.link_pdf, "_blank")}><MaterialIcon icon="get_app" />Descargar PDF</Button>
							}
							{story?.link_ebook &&
								<Button className="btn-download" onClick={()=> window.open(story?.link_ebook, "_blank")}><MaterialIcon icon="get_app" />Descargar EBOOK</Button>
							}
						</div>
					</div>
				}
			</>
		);
	}

}

export default connector(Index);
