import { Story } from '.';

export const story_map = (story: any): Story => ({
    id: story.id,
    title: story.fields.title,
    image: story.fields.image ? story.fields.image[0].fileUrl : '',
    description: story.fields.description,
    thematic: story.fields.thematic ? story.fields.thematic.fields.nombre : '',
    link_pdf: story.fields.link_archivo ? story.fields.link_archivo.href : '',
    link_ebook: story.fields.link_ebook ? story.fields.link_ebook.href : '',
});