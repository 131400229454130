import React from 'react';
import { MenuItem, InputLabel, FormControl, Select, FormHelperText } from '@material-ui/core';
import { SelectOption } from '../interfaces';

export interface InputSelectProps {
    id: string;
    label: string;
    disabled?: boolean;
    options?: SelectOption[];
    value?: string;
    emptyValue?: boolean,
    helpText?: string,
    onChange?: (value?: string) => void
}

class InputSelect extends React.Component<InputSelectProps> {

    render () {
        let optionsList = this.props.options;
        if (this.props.emptyValue) {
            optionsList = [{ value: '', name: 'Vacío' } as SelectOption, ...this.props.options||[]];
        }
        return (
            <FormControl error={!!this.props.helpText} fullWidth>
                <InputLabel htmlFor={this.props.id}>{this.props.label}</InputLabel>
                <Select
                    labelId={`${this.props.id}-label`}
                    disabled={this.props.disabled}
                    id={`${this.props.id}-select`}
                    value={this.props.value}
                    onChange={(e) => this.props.onChange?.(e.target.value as string) }
                    label={this.props.label}
                    inputProps={{
                        name: this.props.label,
                        id: this.props.id,
                    }}
                >
                    {(optionsList||[]).map(
                        option =>
                            <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                    )}
                </Select>
                <FormHelperText>{this.props.helpText}</FormHelperText>
            </FormControl>
        );
    };

}

export { InputSelect };
