import React from 'react';

export interface ButtonProps {
    className?: string,
    onClick?: () => void
}

class Button extends React.Component<ButtonProps> {

    click() {
        const { onClick } = this.props;
        if (onClick) {
            onClick();
        }
    }

    render () {
        return (
            <button className={this.props.className} onClick={() => this.click()}>{this.props.children}</button>
        );
    };

}

export { Button };
