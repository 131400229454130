import React from 'react';
import { Link } from 'react-router-dom';
import { Novel } from '../interfaces';

export interface NovelCardProps {
    novel: Novel,
    size: string,
}

class NovelCard extends React.Component<NovelCardProps> {

    render () {
        const { novel, size } = this.props;
        return (
            <Link to={`/novelas/${novel.id}`} className={`novel-card-${size} col_r1_d2`}>
                {size === 'small' &&
                    novel.image && <img src={novel.image} alt={novel.title} />
                }
                {size === 'big' &&
                    <div className={`novel-card-${size}-left`}>
                        {novel.image && <img src={novel.image} alt={novel.title} />}
                    </div>
                }
                {size === 'big' &&
                    <div className={`story-card-${size}-right`}>
                        {novel.title && <h3>{novel.title}</h3>}
                        {novel.description && <div dangerouslySetInnerHTML={{__html: novel.description}} />}
                        {novel.thematic && <p><b>Temática:</b> {novel.thematic}</p>}
                    </div>
                }
            </Link>
        );
    };

}

export { NovelCard };
