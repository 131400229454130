import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../reducers';
import { Story } from '../../interfaces';

export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_STORIES = 'UPDATE_STORIES';
export const APPEND_STORIES = 'APPEND_STORIES';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateStoriesAction {
    type: typeof UPDATE_STORIES,
    payload: Story[]
}

export interface AppendStoriesAction {
    type: typeof APPEND_STORIES,
    payload: Story[]
}

export type Actions =
UpdateLoadingAction |
UpdateStoriesAction |
AppendStoriesAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
