import React from 'react';
import { FormControl, RadioGroup, FormControlLabel } from '@material-ui/core';
import { SelectOption } from '../interfaces';
import Radio from '@material-ui/core/Radio';

export interface InputRadioProps {
    options?: SelectOption[];
    value?: string;
    onChange?: (event?: React.ChangeEvent<HTMLInputElement>) => void
}

class InputRadio extends React.Component<InputRadioProps> {

    render () {
        return (
            <FormControl component="fieldset">
                <RadioGroup row value={this.props.value} onChange={this.props.onChange}>
                    {(this.props.options||[]).map(
                        (option, index) =>
                            <FormControlLabel key={index} value={option.value} control={<Radio color="default" />} label={option.name} />
                    )}
                </RadioGroup>
            </FormControl>
        );
    };

}

export { InputRadio };
