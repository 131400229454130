import {
    UPDATE_LOADING,
    UPDATE_NOVELS,
    UPDATE_STORIES,
    Actions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Novel, Story } from "../../interfaces";

export interface State {
    loading: boolean,
    novels: Novel[],
    stories: Story[],
}

const INITIAL_STATE: State = {
    loading: false,
    novels: [],
    stories: [],
};

const reducer: Reducer<State, Actions> = (state = INITIAL_STATE, action: Actions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_NOVELS:
            return { ...state, novels: action.payload };
        case UPDATE_STORIES:
            return { ...state, stories: action.payload };
        default:
            return state;
    }
}

export default reducer;